import { Auth0Context, Auth0ContextInterface, withAuthenticationRequired } from '@auth0/auth0-react';
import { IInternalAuthentication } from '@edgebox/api-rest-client';
import { UserType } from '@edgebox/data-definitions';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { AuthenticationContext, ContentSyncPageLoading } from '../common';
import { API_DOMAIN } from '../constants';
import { ErrorPage } from './ErrorPage';
import { VerifyEmail } from './Login/VerifyEmail';

export interface IAuth0Authentication extends IInternalAuthentication {
  error?: Error & { error?: string };
  tokenUser?: {
    sub?: string;
    permissions?: string[];
    email_verified?: boolean;
  };
}

interface IProps {
  requireAuthentication: boolean;
  children: React.ReactNode;
  requireReauthentication?: boolean;
}

interface IState extends IAuth0Authentication {
  reauthenticate?: boolean;
}

const COOKIE_LOCAL_JWT = 'local-token';

function setCookie(name: string, value: string, days: number = 1) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1_000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
function getCookie(name: string) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const isLocal = process.env.REACT_APP_ENVIRONMENT_TYPE === 'local';

export class Auth0AuthenticationProvider extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const auth0 = () => this.auth0;

    this.state = {
      get isAuthenticated() {
        return auth0().isAuthenticated;
      },
      get error() {
        return auth0().error;
      },
      get tokenUser() {
        return auth0().user;
      },
      isLoading: true,
      logout: () => {
        if (isLocal) {
          eraseCookie(COOKIE_LOCAL_JWT);
        }
        auth0().logout({
          returnTo: window.location.origin,
        });
      },
      jwt: undefined,
      loginWithRedirect: (redirectPath?: string, userType?: UserType) => {
        if (isLocal && getCookie(COOKIE_LOCAL_JWT)) {
          return;
        }
        auth0().loginWithRedirect();
      },
      handleRedirectCallback: () => {},
    };
  }

  static contextType = Auth0Context;
  context!: Auth0ContextInterface;
  get auth0(): Auth0ContextInterface {
    return this.context;
  }

  componentDidMount() {
    if (isLocal) {
      if (window.location.pathname.substr(0, 13) === '/local-login/') {
        setCookie(COOKIE_LOCAL_JWT, window.location.pathname.substr(13));
        window.location.pathname = '/';
        return;
      }
      const local = getCookie(COOKIE_LOCAL_JWT);
      if (local) {
        this.setState({
          jwt: local,
          isLoading: false,
        });
        return;
      }
    }

    this.checkToken();
  }

  __isMounted = true;
  componentWillUnmount() {
    this.__isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    this.checkToken();
  }

  tryingAuth = false;
  checkToken() {
    (window as any).AUTH0_CONTEXT_VALUE = this.auth0;
    if (this.auth0.user) {
      (window as any).LATEST_USER = this.auth0.user;
    }
    if (this.auth0.isLoading) {
      return;
    }
    if (this.tryingAuth) {
      return;
    }
    this.tryingAuth = true;

    // Unfortunately, auth0 doesn't work reliably at all. Even when using the
    // same browser, it will sometimes just randomly refuse the login and lead
    // to constant "Login required" errors, random logouts etc.
    if (this.state.jwt) {
      this.setState({
        jwt: undefined,
      });
    }
    if (this.state.isLoading) {
      this.auth0
        .getAccessTokenSilently({})
        .then((value) => {
          if (value) {
            this.setState({ jwt: value });
          }
          /*else {
            tryWithPopup();
            return;
          }*/
          this.setState({
            isLoading: false,
          });
        })
        .catch((error: any) => {
          console.error(error);
          this.setState({
            isLoading: false,
            error: error.error === 'login_required' || error.message === 'Login required' ? undefined : error,
          });
        });
    }
  }

  render() {
    const { requireAuthentication, requireReauthentication } = this.props;
    const { error, reauthenticate } = this.state;
    const { isLoading, isAuthenticated } = this.auth0;

    if (isLoading || this.state.isLoading) {
      return <ContentSyncPageLoading />;
    }

    if (
      (!isAuthenticated && requireAuthentication) ||
      requireReauthentication ||
      reauthenticate ||
      error?.error === 'mfa_required' ||
      error?.error === 'mfa_registration_required'
    ) {
      const RequireAuth = withAuthenticationRequired((props: {}) => this.props.children as React.ReactElement, {
        onRedirecting: () => <ContentSyncPageLoading />,
        loginOptions: {
          ...(error?.error === 'mfa_required' || error?.error === 'mfa_registration_required' || reauthenticate || requireReauthentication
            ? {
                prompt: 'login',
              }
            : {}),
        },
      });
      return <RequireAuth />;
    }

    const invitation = window.localStorage.getItem('invitation');
    if (this.state.jwt) {
      if (invitation) {
        window.localStorage.removeItem('invitation');
        const url = `//${API_DOMAIN}/authentication/invitation-complete/${invitation}?jwt=${this.state.jwt}`;

        window.location.href = url;

        return <div>Redirecting...</div>;
      }
    } else if (error) {
      return (
        <ErrorPage>
          <Alert variant="danger">
            We were not able to log you in.
            <br />
            <br />
            {error.error === 'too_many_attempts' ? (
              <strong>There were too many login attempts. Please follow the instructions in the email we sent you.</strong>
            ) : error.error === 'unauthorized' ? (
              <strong>Your account has been blocked. Please reach out to our support to unblock it.</strong>
            ) : (
              <em>Technical details: {error.message}</em>
            )}
            <br />
            <br />
            <Button variant="light" onClick={() => this.setState({ reauthenticate: true })}>
              Try again
            </Button>
          </Alert>
        </ErrorPage>
      );
    } else if (invitation) {
      return <VerifyEmail />;
    }

    return <AuthenticationContext.Provider value={this.state}>{this.props.children}</AuthenticationContext.Provider>;
  }
}
