import { BasketStatus } from '@edgebox/data-definitions';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { DataDefinitionsEnumValue } from '../../../common';

interface IProps {
  status: BasketStatus;
}

export class BackendBasketStatus extends React.Component<IProps, {}> {
  render() {
    const { status } = this.props;

    return (
      <Badge
        bg={
          status === BasketStatus.Requested
            ? 'danger'
            : status === BasketStatus.Proposal
            ? 'warning'
            : status === BasketStatus.Purchased
            ? 'success'
            : 'light'
        }
      >
        <DataDefinitionsEnumValue enumName="BasketStatus" keyName={status} />
      </Badge>
    );
  }
}
