import { IInternalAuthentication } from '@edgebox/api-rest-client';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { Tracking, TrackingEvent } from '../../contexts/TrackingContext';
import { ExternalLink } from '@edgebox/react-components';
import { DisclaimerFooter } from './DisclaimerFooter';
import './Login.scss';
import { LoginHeader } from './LoginHeader';
import { Auth0Context } from '@auth0/auth0-react';
import { AuthenticationContext, Logo } from '../../common';

export class Login extends Component<{}, {}> {
  static contextType = AuthenticationContext;
  context!: IInternalAuthentication;
  get authentication(): IInternalAuthentication {
    return this.context;
  }

  public render() {
    return (
      <div className="mx-auto">
        <LoginHeader />

        <Container className={'login'}>
          <div className={'logo'}>
            <Logo height={75} />
          </div>
          <h1>Content Sync</h1>
          <h2 className={'mt-3 text-muted'}>Create Content Once &amp; Use It Everywhere.</h2>

          <div className={'card-deck with-highlight'}>
            <Card className={'highlight p-0'} border={'primary'}>
              <Card.Body className="p-0 pt-4">
                <Card.Title>Ready to sync?</Card.Title>

                <Card.Text>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/PynGV0uwSHc"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Card.Text>

                <Card.Footer>
                  <div>
                    <Auth0Context.Consumer>
                      {(auth0) => (
                        <Button
                          variant="primary"
                          onClick={Tracking.callback({ type: TrackingEvent.StartSignUpAsCustomer }, () => {
                            auth0.loginWithRedirect({
                              screen_hint: 'signup',
                            });
                          })}
                        >
                          Register
                        </Button>
                      )}
                    </Auth0Context.Consumer>
                  </div>
                  <div className={'mt-2 text-muted'}>
                    <ExternalLink to={'https://cms-content-sync.io/'}>Learn more about Content Sync</ExternalLink>
                  </div>
                </Card.Footer>
              </Card.Body>
            </Card>
          </div>
        </Container>

        <div className={'mt-5 mb-5'}>&nbsp;</div>

        <DisclaimerFooter />
      </div>
    );
  }
}
