import { ClientContractEntity, ClientSyncCoreDraft, ClientSyncCoreEntity } from '@edgebox/api-rest-client';
import { DateTimeFormat, ISerializedEntity } from '@edgebox/data-definition-kit';
import {
  CustomerRole,
  DataDefinitionsEnumTranslator,
  ISerializedSyncCoreProperties,
  SyncCoreStatus,
  SyncCoreType,
  UserType,
} from '@edgebox/data-definitions';
import { FormField } from '@edgebox/react-components';
import { SalesRegion, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import React, { PropsWithChildren } from 'react';
import { Button } from 'react-bootstrap';
import { EditMode, EntityContext } from '../../../contexts/EntityContext';
import { ContractLink } from '../../Billing/Links/ContractLink';
import { CustomerLink } from '../../Billing/Links/CustomerLink';
import { ContractSelector } from '../../Billing/Selectors/ContractSelector';
import { EntityForm, entityFormWithValidation, IEntityFormProps, IEntityFormState } from '../../Form/EntityForm';
import { EntityFormRow } from '../../Form/EntityFormRow';
import { EnumAsCheckboxes } from '../../Form/EnumAsCheckboxes';
import { EnumAsDropdown } from '../../Form/EnumAsDropdown';
import { EditName } from '../../Form/Properties/EditName';
import { DataDefinitionsEnumValue } from '../../Localization/DataDefinitionsEnumValue';
import { FormatDateTime } from '../../Localization/FormatDateTime';
import { ViewSyncCoreSecret } from '../Views/ViewSyncCoreSecret';

type OnSaveCallback<Type> = (entity: Type) => Promise<void>;
type OnProfileCompleteCallback = (entity: ClientSyncCoreEntity) => void;

interface IProps<Type extends ClientSyncCoreDraft> extends IEntityFormProps<Type, ISerializedSyncCoreProperties>, PropsWithChildren {
  mode: 'view-full' | 'edit-full' | 'create' | 'inline-edit';
  onSave: OnSaveCallback<Type>;
  onProfileComplete?: OnProfileCompleteCallback;
}

const EDIT_MODES = {
  // Setup Sync Core.
  create: EditMode.Full,
  // Viewing their own profile
  'inline-edit': EditMode.Inline,
  'view-full': EditMode.None,
  'edit-full': EditMode.Full,
};

interface IState<Type extends ClientSyncCoreDraft> extends IEntityFormState<Type> {
  savedEntity?: ClientSyncCoreEntity;
  selectedContract?: ClientContractEntity;
  updatingLastActivity?: boolean;
}

const FormWithValidation = entityFormWithValidation<ISerializedSyncCoreProperties, ClientSyncCoreDraft>(
  ClientSyncCoreDraft,
  ClientSyncCoreEntity
);

// TODO: show throbber when saving / loading.

export class SyncCoreForm<Type extends ClientSyncCoreDraft = ClientSyncCoreDraft> extends EntityForm<
  Type,
  ISerializedSyncCoreProperties,
  IProps<Type>,
  IState<Type>
> {
  constructor(props: IProps<Type>) {
    super(props, {
      savedEntity: (props.entity as any).id ? (props.entity as any) : undefined,
      edit: EDIT_MODES[props.mode],
      extendProperties: [],
    });
  }

  async load(): Promise<Partial<IState<Type>>> {
    const selectedContract = await this.props.entity.contract?.get();

    return {
      selectedContract,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps<Type>>, prevState: Readonly<IState<Type>>, snapshot?: any): void {
    // Person was saved => show "Upload profile picture" component.
    if (!(prevProps.entity as any).id && (this.props.entity as any).id) {
      this.createdNew();
    }
    if (this.props.mode !== prevProps.mode) {
      this.setState({
        edit: EDIT_MODES[this.props.mode],
      });
    }
  }

  async createdNew() {
    this.setState({
      savedEntity: this.props.entity as any,
    });
  }

  render(): React.ReactElement {
    const { children, onToggleEdit, mode } = this.props;
    const { selectedContract, updatingLastActivity } = this.state;

    const renderBackendProperties = this.api.currentUser!.type === UserType.Internal;

    const privileged = renderBackendProperties || this.api.currentUser!.customerRoles?.includes(CustomerRole.Owner);
    const showSecret = privileged && mode === 'view-full' && this.props.entity.status !== SyncCoreStatus.Retired;

    const createNew = mode === 'create';
    const editing = createNew || mode === 'edit-full';

    if (this.props.entity.contract?.getId() && !selectedContract) {
      return this.renderRequest();
    }

    return (
      <EntityContext.Provider value={this.entityContext}>
        <FormWithValidation
          {...this.formProperties()}
          apiComponent={this}
          initialValues={this.props.entity as ClientSyncCoreDraft}
          labels={'above'}
          onSubmit={async (entity) => {
            this.setState({
              extendProperties: [],
            });

            await this.props.onSave(entity as Type);
          }}
        >
          {({ values, setValue }) => {
            const entityId = (values as ISerializedSyncCoreProperties & ISerializedEntity).id;

            return (
              <>
                {this.props.name || <EditName value={values.name} label={'Name'} onToggleEdit={onToggleEdit} />}

                <EntityFormRow<ClientSyncCoreEntity>
                  name={'baseUrl'}
                  label={'URL'}
                  view={() => values.baseUrl}
                  edit={() => (
                    <FormField
                      name={'baseUrl'}
                      type={'text'}
                      noRow={true}
                      placeholder={'https://.../sync-core'}
                      description={
                        'The URL that your sites and users can use to connect to the Sync Core. You can still edit this later, but you will have to re-register your sites when changing it.'
                      }
                    />
                  )}
                />

                {renderBackendProperties && (
                  <EntityFormRow<ClientSyncCoreEntity>
                    name={'type'}
                    label={'Type'}
                    view={() => <DataDefinitionsEnumValue enumName={'SyncCoreType'} keyName={values.type} />}
                  />
                )}

                {renderBackendProperties && (
                  <EntityFormRow<ClientSyncCoreEntity>
                    name={'customer'}
                    label={'Type'}
                    view={values.customer?.id ? () => <CustomerLink entityId={values.customer!.id!} /> : undefined}
                  />
                )}

                {renderBackendProperties && createNew && values.regions && (
                  <EnumAsCheckboxes<ClientSyncCoreEntity, SalesRegion>
                    name={'regions'}
                    label={'Regions'}
                    enumValues={SyncCoreDataDefinitionsEnumTranslator.translateEnum('SalesRegion')}
                    currentValue={values.regions}
                    setValue={(name, regions) => setValue('regions', regions)}
                  />
                )}

                {values.type === SyncCoreType.OnPremise && (
                  <EntityFormRow<ClientSyncCoreEntity>
                    name={'contract'}
                    label={'Contract'}
                    view={values.contract?.id ? () => <ContractLink entityId={values.contract!.id!} /> : undefined}
                    edit={
                      createNew
                        ? () => (
                            <ContractSelector
                              name={'contract'}
                              value={selectedContract}
                              forCustomer={values.customer?.id}
                              onSelected={(selectedContract) => {
                                setValue('contract.id' as 'contract', selectedContract.id);
                                this.setState({
                                  selectedContract,
                                });
                              }}
                            />
                          )
                        : undefined
                    }
                  />
                )}

                {editing && (
                  <EnumAsDropdown<ClientSyncCoreEntity, SyncCoreStatus>
                    enumValues={DataDefinitionsEnumTranslator.translateEnum('SyncCoreStatus')}
                    currentValue={values.status}
                    name={'status'}
                    label={'Status'}
                    setValue={setValue as any}
                  />
                )}

                <EntityFormRow<ClientSyncCoreEntity>
                  name={'lastActivity'}
                  label={'Last activity'}
                  view={
                    values.lastActivity
                      ? () => <FormatDateTime date={values.lastActivity!} format={DateTimeFormat.Date} />
                      : entityId
                      ? () => (
                          <span>
                            Not connected{' '}
                            <Button
                              disabled={updatingLastActivity}
                              variant={'light'}
                              onClick={async () => {
                                this.setState({
                                  updatingLastActivity: true,
                                });

                                const updated = await this.api.syndication.syncCores.item(entityId);
                                if (updated.lastActivity) {
                                  setValue('lastActivity', updated.lastActivity.valueOf());
                                }

                                this.setState({
                                  updatingLastActivity: false,
                                });
                              }}
                            >
                              Refresh
                            </Button>
                          </span>
                        )
                      : undefined
                  }
                />

                {showSecret && (
                  <EntityFormRow<ClientSyncCoreEntity> name={'uuid'} label={'UUID'} view={values.uuid ? () => values.uuid : undefined} />
                )}

                {showSecret && (
                  <EntityFormRow<ClientSyncCoreEntity>
                    name={'secret'}
                    label={'Secret'}
                    view={entityId ? () => <ViewSyncCoreSecret entityId={entityId} /> : undefined}
                  />
                )}

                {children}
              </>
            );
          }}
        </FormWithValidation>
      </EntityContext.Provider>
    );
  }
}
