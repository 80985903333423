import { ClientCustomerEntity, ClientUserEntity } from '@edgebox/api-rest-client';
import { EditButton, LeftRightContainer, LeftRightH1, Right } from '@edgebox/react-components';
import React, { PropsWithChildren } from 'react';
import { Alert, Button, Col, FormLabel, Image, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Tracking, TrackingEvent } from '../../../contexts/TrackingContext';
import { ContentBox } from '../../Shared/ContentBox';
import ProfileImage from '../../../images/undraw_personal_information_re_vw8a.svg';
import { SwitchButton } from '../../Shared/SwitchButton';
import { CustomerRole } from '@edgebox/data-definitions';
import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react';
import { ApiComponent, IApiComponentState, UserForm } from '../../../common';
import { HeadlineWithBreadcrumbNavigation } from '../../../common/components/BreadcrumbNavigation';
import { UserStatusIcon } from '../../Icons';

interface IProps extends PropsWithChildren {}

interface IState extends IApiComponentState {
  editPerson?: ClientUserEntity;
  saving?: boolean;
  changedAt?: number;
  customer?: ClientCustomerEntity;
  reauthenticate?: boolean;
}

type MfaOption = 'none' | 'user' | 'customer';

class ForceAuthentication extends React.Component<PropsWithChildren, {}> {
  static contextType = Auth0Context;
  context!: Auth0ContextInterface;
  get auth0(): Auth0ContextInterface {
    return this.context;
  }

  componentDidMount() {
    this.auth0.loginWithRedirect({
      prompt: 'login',
    });
  }

  render() {
    return this.props.children || null;
  }
}

export class AccountProfile extends ApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    Tracking.startTimer(TrackingEvent.SavedPersonalProfile);
    const customer = (await this.api.currentUser!.customer!.get())!;
    return {
      customer,
    };
  }

  render() {
    const { customer, editPerson, changedAt, reauthenticate, saving } = this.state;

    if (!customer) {
      return this.renderRequest();
    }

    if (reauthenticate) {
      return (
        <ForceAuthentication>
          <Alert variant="info">Redirecting you for the MFA setup...</Alert>
        </ForceAuthentication>
      );
    }

    const person = this.api.currentUser!;

    const isOwner = person.customerRoles?.includes(CustomerRole.Owner);

    const currentMfaOption: MfaOption = customer.requireMfa ? 'customer' : person.requireMfa ? 'user' : 'none';

    return (
      <Container>
        <LeftRightContainer
          left={
            <HeadlineWithBreadcrumbNavigation>
              <UserStatusIcon status={person.status} /> {person.firstName} {person.lastName}
            </HeadlineWithBreadcrumbNavigation>
          }
          right={
            editPerson ? undefined : (
              <EditButton
                disabled={saving}
                onClick={() =>
                  this.setState({
                    editPerson: new ClientUserEntity(person),
                  })
                }
              />
            )
          }
        />

        <ContentBox>
          <Row>
            <Col xs={6} className={'p-5'}>
              <Image src={ProfileImage} width={'100%'} />
            </Col>
            <Col className={'pt-5'}>
              <UserForm
                name={<></>}
                // Reset the "extendProperties" after saving for inline-editing so that the profile picture can
                // be changed right away.
                key={editPerson ? 'edit' : changedAt}
                mode={editPerson ? 'edit-full' : 'view-full'}
                onSave={async (update) => {
                  this.setState({
                    saving: true,
                  });

                  this.api.currentUser = await this.api.authentication.users.update(update);

                  this.setState({
                    editPerson: undefined,
                    saving: false,
                    changedAt: Date.now(),
                  });

                  Tracking.track(TrackingEvent.SavedPersonalProfile);
                }}
                entity={editPerson || person}
              >
                {editPerson ? (
                  <Right>
                    <Button variant={'light'} onClick={() => this.setState({ editPerson: undefined })}>
                      Cancel
                    </Button>
                    <Button variant={'primary'} type={'submit'}>
                      Save
                    </Button>
                  </Right>
                ) : undefined}
              </UserForm>

              {!editPerson && (
                <div className="ps-3">
                  <FormLabel>Require Multi-Factor Authentication</FormLabel>
                  <SwitchButton<MfaOption>
                    options={[
                      { label: 'Only for suspicious logins', value: 'none' as const },
                      { label: isOwner ? 'Always but only for me' : 'Always', value: 'user' as const },
                      ...(isOwner ? [{ label: 'Always and for everyone', value: 'customer' as const }] : []),
                    ]}
                    onChange={async (option) => {
                      this.setState({ saving: true });
                      let reauthenticate = !customer.requireMfa && !person.requireMfa;
                      let updatedCustomer: ClientCustomerEntity | undefined = undefined;

                      if (option === 'customer') {
                        person.requireMfa = true;
                        const updatedPerson = await this.api.authentication.users.update(person);
                        this.api.currentUser = updatedPerson;

                        customer.requireMfa = true;
                        updatedCustomer = await this.api.billing.customers.updateSelf(customer);
                      } else if (option === 'user') {
                        customer.requireMfa = false;
                        updatedCustomer = await this.api.billing.customers.updateSelf(customer);

                        if (!person.requireMfa) {
                          person.requireMfa = true;
                          const updatedPerson = await this.api.authentication.users.update(person);
                          this.api.currentUser = updatedPerson;
                        }
                      } else {
                        if (customer.requireMfa) {
                          customer.requireMfa = false;
                          updatedCustomer = await this.api.billing.customers.updateSelf(customer);
                        }

                        if (person.requireMfa) {
                          person.requireMfa = false;
                          const updatedPerson = await this.api.authentication.users.update(person);
                          this.api.currentUser = updatedPerson;
                        }
                      }

                      this.setState({
                        customer: updatedCustomer || customer,
                        reauthenticate,
                        saving: false,
                      });
                    }}
                    selected={currentMfaOption}
                    disabled={saving}
                  />
                </div>
              )}
            </Col>
          </Row>
        </ContentBox>
      </Container>
    );
  }
}
