import { LogItemListResponse } from '@edgebox/api-rest-client/dist/services/api';
import { InternalId } from '@edgebox/data-definition-kit';
import { HeaderCol, IconButton } from '@edgebox/react-components';
import moment from 'moment';
import React from 'react';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import {
  ApiComponent,
  ContractLink,
  ContractRevisionLink,
  CustomerLink,
  IApiComponentState,
  ProjectLink,
  RequestReason,
  SiteLink,
  SyncCoreLink,
  UserName,
} from '../../common/index';
import { ContentBox } from '../Shared/ContentBox';
import { InvoiceLink } from './InvoiceLink';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';

type LogLevelName = 'error' | 'warn' | 'info' | 'verbose' | 'debug';
const LOG_LEVELS = ['error', 'warn', 'info', 'verbose', 'debug'] as const;

export interface IContextIdFilters {
  customerId?: InternalId;
  contractId?: InternalId;
  contractRevisionId?: InternalId;
  projectId?: InternalId;
  siteId?: InternalId;
  userId?: InternalId;
  invoiceId?: InternalId;
  syncCoreId?: InternalId;
  messageId?: InternalId;
  fileId?: InternalId;
  migrationId?: InternalId;
  remoteEntityRevisionId?: InternalId;
  syndicationId?: InternalId;
}
type ContextIdFilter = keyof IContextIdFilters;
function isContextIdFilter(name: string): name is ContextIdFilter {
  return CONTEXT_ID_FILTER_NAMES.includes(name as ContextIdFilter);
}
const CONTEXT_ID_FILTER_NAMES: ContextIdFilter[] = [
  'customerId',
  'contractId',
  'contractRevisionId',
  'projectId',
  'siteId',
  'userId',
  'invoiceId',
  'syncCoreId',
  'messageId',
  'fileId',
  'migrationId',
  'remoteEntityRevisionId',
  'syndicationId',
];
const HIDE_CONTEXT_FOR_FILTERS: { [name in ContextIdFilter]?: ContextIdFilter[] } = {
  customerId: ['customerId'],
  contractId: ['customerId', 'contractId'],
  contractRevisionId: ['customerId', 'contractId', 'contractRevisionId'],
  projectId: ['customerId', 'contractId', 'contractRevisionId', 'projectId'],
  siteId: ['customerId', 'contractId', 'contractRevisionId', 'projectId', 'siteId'],
  invoiceId: ['customerId', 'contractId', 'contractRevisionId', 'invoiceId'],
  userId: ['customerId', 'userId'],
};

interface IProps extends IContextIdFilters {
  id: string;
  context?: string;
}

interface ITextFilters extends IContextIdFilters {
  search?: string;
  filterPattern?: string;
  logLevel?: LogLevelName;
  hostname?: string;
  version?: string;
  serviceName?: string;
  serviceTypes?: string;
  component?: string;
}

type RelativeTimeFilter = '1m' | '5m' | '15m' | '30m' | '1h' | '2h' | '4h' | '12h' | '1d' | '2d' | '1w' | '2w' | '4w' | 'custom';
const RELATIVE_FILTER_OPTIONS = ['1m', '5m', '15m', '30m', '1h', '2h', '4h', '12h', '1d', '2d', '1w', '2w', '4w', 'custom'] as const;
function makeRelativeTimeFilterAbsolute(filters: IFilters) {
  const end = moment();
  if (filters.relativeTime === 'custom') {
    return {
      start: filters.start,
      end: filters.end,
    };
  }
  const unit = filters.relativeTime.charAt(filters.relativeTime.length - 1);
  const amount = parseInt(filters.relativeTime.substring(0, filters.relativeTime.length - 1));
  const start = end.clone();
  if (unit === 'm') {
    start.subtract(amount, 'minutes');
  } else if (unit === 'h') {
    start.subtract(amount, 'hours');
  } else if (unit === 'd') {
    start.subtract(amount, 'days');
  } else if (unit === 'w') {
    start.subtract(amount, 'weeks');
  }

  return {
    start,
    end,
  };
}
interface IFilters extends ITextFilters {
  start: moment.Moment;
  end: moment.Moment;
  relativeTime: RelativeTimeFilter;
  context: string;
}

interface IState extends IApiComponentState {
  filters: IFilters;
  result?: LogItemListResponse;
  updateId?: number;
  token?: string;
  showFullComponent?: boolean;
  showAdvanced?: boolean;
}

export class PagedLogItemList extends ApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      filters: {
        start: moment().subtract(1, 'day'),
        end: moment(),
        relativeTime: '1d',
        context: props.context || 'api',
        ...CONTEXT_ID_FILTER_NAMES.filter((c) => !!props[c]).reduce((o, c) => ({ ...o, [c]: props[c] }), {}),
      },
    });

    this.update = this.wrapApiCallFunction(async (token?: string) => {
      await this._update(token);
    }, RequestReason.RequestDetails);
  }

  async load() {
    return (await this._update())!;
  }

  update: (nextToken?: string) => Promise<void>;
  protected delayedUpdate: any = undefined;
  async _update(nextToken?: string, returnOnly = false) {
    const updateId = Date.now();
    const { filters } = this.state;
    const { context, start, end, search } = filters;
    this.setState({
      updateId,
      token: nextToken,
      result: undefined,
    });
    try {
      let result: LogItemListResponse;
      do {
        result = await this.api.logging.list({
          context,
          end,
          start,
          search,
          level: filters.logLevel,
          hostname: filters.hostname,
          version: filters.version,
          serviceName: filters.serviceName,
          serviceTypes: filters.serviceTypes,
          component: filters.component,
          filterPattern: filters.filterPattern,
          ...CONTEXT_ID_FILTER_NAMES.filter((c) => !!filters[c]).reduce((o, c) => ({ ...o, [c]: filters[c] }), {}),
          nextToken,
        });
        nextToken = result.nextToken || undefined;
      } while (!result.items.length && result.hasMore && nextToken);
      // Maybe a newer request is already on the way.
      if (updateId !== this.state.updateId) {
        return;
      }
      if (returnOnly) {
        return { result };
      }
      this.setState({ result, updateId: undefined });
    } catch (e) {
      this.setState({ updateId: undefined });
      throw e;
    }
  }

  render() {
    const { id } = this.props;
    const { filters, result, updateId, token, showFullComponent, showAdvanced } = this.state;

    const disabled = !!updateId;

    const hideProperties: string[] = [
      'message',
      'timestamp',
      'level',
      'context',
      'hostname',
      'version',
      'serviceName',
      'serviceTypes',
      ...CONTEXT_ID_FILTER_NAMES.filter((c) => !!this.props[c] && !!HIDE_CONTEXT_FOR_FILTERS[c])
        .map((c) => HIDE_CONTEXT_FOR_FILTERS[c]!)
        .reduce((a, b) => [...a, ...b], []),
    ];

    // TODO: Add filter for context, time interval.

    const needsUpdate = (updateImmediately = false) => {
      if (this.delayedUpdate) {
        clearTimeout(this.delayedUpdate);
      }
      this.delayedUpdate = setTimeout(this.update.bind(this), updateImmediately ? 0 : 500);
    };
    const setFilter = (name: string, value: any, updateImmediately = false) => {
      this.setState({
        filters: {
          ...filters,
          [name]: value,
        },
        showAdvanced: showAdvanced || !['context', 'start', 'end', 'relativeTime', 'logLevel', 'search'].includes(name),
      });

      needsUpdate(updateImmediately);
    };
    function TextFilter({ name, help }: { name: string; help?: string }) {
      return (
        <Form.Control
          placeholder={help || name}
          disabled={disabled}
          value={filters[name as keyof ITextFilters] || ''}
          key={name}
          name={name}
          onChange={(e) => {
            setFilter(name, e.target.value);
          }}
        />
      );
    }

    return (
      <div id={id}>
        <Row>
          <Col xs={1} className={'ps-0'}>
            <select
              disabled={disabled}
              onChange={(e) => {
                this.setState({ filters: { ...filters, context: e.target.value } });
                needsUpdate(true);
              }}
              value={filters.context}
            >
              <option value={'api'}>API</option>
              <option value={'sync-core-eu1'}>Sync Core - EU1</option>
              <option value={'sync-core-us1'}>Sync Core - US1</option>
            </select>
          </Col>
          <Col xs={4}>
            <Form.Control
              placeholder={'E.g. *term* or "exact message" or { $.prop = "value" }'}
              disabled={disabled}
              value={filters.search || filters.filterPattern || ''}
              key={'search'}
              name={'search'}
              onChange={(e) => {
                const value = e.target.value;
                const isPattern = value[0] === '{' || (!value && !!filters.filterPattern);
                setFilter(isPattern ? 'search' : 'filterPattern', undefined);
                setFilter(isPattern ? 'filterPattern' : 'search', value);
              }}
            />
          </Col>
          <Col xs={1}>
            <select
              disabled={disabled}
              onChange={(e) => {
                this.setState({ filters: { ...filters, logLevel: e.target.value as LogLevelName } });
                needsUpdate(true);
              }}
            >
              <option value="" selected={!filters.logLevel}>
                Any type
              </option>
              {LOG_LEVELS.map((c) => (
                <option key={c} value={c} selected={filters.logLevel === c}>
                  {c}
                </option>
              ))}
            </select>
          </Col>
          {filters.relativeTime === 'custom' ? (
            <>
              <Col xs={2}>
                <Form.Control
                  prefix=">"
                  value={filters.start.format()}
                  disabled={disabled}
                  onChange={(e) => {
                    const start = moment(e.target.value);
                    if (!start.isValid()) {
                      return;
                    }
                    setFilter('start', start);
                  }}
                />
              </Col>
              <Col xs={2}>
                <Form.Control
                  prefix="<"
                  value={filters.end.format()}
                  disabled={disabled}
                  onChange={(e) => {
                    const end = moment(e.target.value);
                    if (!end.isValid()) {
                      return;
                    }
                    setFilter('end', end);
                  }}
                />
              </Col>
            </>
          ) : (
            <Col xs={1}>
              <select
                disabled={disabled}
                onChange={(e) => {
                  const relativeTime = e.target.value as RelativeTimeFilter;
                  const newFilters = { ...filters, relativeTime };
                  this.setState({ filters: { ...newFilters, ...makeRelativeTimeFilterAbsolute(newFilters) } });
                  needsUpdate(true);
                }}
              >
                {RELATIVE_FILTER_OPTIONS.map((c) => (
                  <option key={c} selected={filters.relativeTime === c}>
                    {c}
                  </option>
                ))}
              </select>
            </Col>
          )}
          <Col xs={1}>
            <Form.Check
              inline
              type={'checkbox'}
              id={`${id}-show-advanced`}
              label={'Advanced'}
              required
              className="fw-normal me-0 ms-2"
              checked={!!showAdvanced}
              onChange={(e) => {
                this.setState({
                  showAdvanced: e.target.checked,
                });
              }}
            />
          </Col>
        </Row>
        {showAdvanced && (
          <Row className="mt-2">
            <Col xs={2} className="ps-0">
              {TextFilter({ name: 'hostname' })}
            </Col>
            <Col xs={2}>{TextFilter({ name: 'serviceName' })}</Col>
            <Col xs={2}>{TextFilter({ name: 'serviceTypes' })}</Col>
            <Col xs={2}>{TextFilter({ name: 'version' })}</Col>
            <Col xs={2}>{TextFilter({ name: 'component' })}</Col>
          </Row>
        )}
        {showAdvanced && (
          <Row className="mt-2">
            <Col xs={2} className="ps-0">
              <select
                disabled={disabled}
                onChange={(e) => {
                  this.setState({ filters: { ...filters, [e.target.value]: '000000000000000000000000' } });
                  needsUpdate(true);
                }}
              >
                <option value={''} selected>
                  Add ID filter...
                </option>
                {CONTEXT_ID_FILTER_NAMES.map((c) => (
                  <option value={c} key={c} selected={false}>
                    {c}
                  </option>
                ))}
              </select>
            </Col>
            {CONTEXT_ID_FILTER_NAMES.map((name) => {
              if (!filters[name]) {
                return null;
              }
              return (
                <Col key={name} title={name} xs={2}>
                  {TextFilter({ name })}
                </Col>
              );
            })}
          </Row>
        )}

        <ContentBox className="mt-3">
          <Row>
            <HeaderCol xs={2}>Timestamp</HeaderCol>
            <HeaderCol xs={2}>
              Component{' '}
              <Form.Check
                inline
                type={'checkbox'}
                id={`${id}-show-full-component`}
                label={'Full'}
                required
                className="fw-normal me-0 ms-2"
                onChange={(e) => {
                  this.setState({
                    showFullComponent: e.target.checked,
                  });
                }}
              />
            </HeaderCol>
            <HeaderCol xs={4}>Message</HeaderCol>
            <HeaderCol xs={4}>Context</HeaderCol>
          </Row>
          {result ? (
            result.items.length ? (
              result.items.map((item, index) => {
                let data: any = undefined;
                try {
                  if (item.message) {
                    data = JSON.parse(item.message);
                  }
                } catch (e) {}

                if (data) {
                  return (
                    <Row key={index} className="mt-2">
                      <Col xs={2}>
                        {data.timestamp || item.timestamp ? moment(data.timestamp || item.timestamp).format('YYYY/MM/DD HH:mm:ss.SSS') : ''}
                      </Col>
                      <Col xs={2}>
                        {showFullComponent ? (
                          <>
                            <Badge
                              className="ms-0 cursor-pointer"
                              bg="light"
                              style={{ opacity: 0.8 }}
                              onClick={() => {
                                setFilter(
                                  data.hostname ? 'hostname' : 'serviceName',
                                  data.hostname ? data.hostname : data.serviceName,
                                  true
                                );
                              }}
                            >
                              {data.hostname || data.serviceName || 'host'}
                            </Badge>
                            <br />
                            <Badge
                              className="ms-0 cursor-pointer"
                              bg="light"
                              style={{ opacity: 0.9 }}
                              onClick={() => {
                                setFilter('serviceTypes', data.serviceTypes, true);
                              }}
                            >
                              {data.serviceTypes || 'default'}
                            </Badge>
                            @
                            <Badge
                              className="ms-0 cursor-pointer"
                              bg="light"
                              style={{ opacity: 0.9 }}
                              onClick={() => {
                                setFilter('version', data.version, true);
                              }}
                            >
                              {data.version || 'dev'}
                            </Badge>
                            <br />
                          </>
                        ) : undefined}
                        <Badge
                          className="ms-0 cursor-pointer"
                          bg="light"
                          style={{ opacity: 1 }}
                          onClick={() => {
                            setFilter('component', data.context, true);
                          }}
                        >
                          {data.context}
                        </Badge>
                      </Col>
                      <Col
                        xs={4}
                        className={
                          data.level === 'error'
                            ? 'text-danger'
                            : data.level === 'warning'
                            ? 'text-warning'
                            : data.level === 'verbose' || data.level === 'debug'
                            ? 'text-muted'
                            : undefined
                        }
                      >
                        {data.message || <em>(empty)</em>}
                      </Col>
                      <Col xs={4}>
                        {Object.entries(data)
                          .filter(([name]) => !hideProperties.includes(name))
                          .map(([name, value]) => {
                            let displayName: string;
                            let displayValue: React.ReactNode;
                            if (isContextIdFilter(name)) {
                              displayName = name.substring(0, name.length - 2);
                              if (filters.context === 'api') {
                                if (name === 'customerId') {
                                  displayValue = <CustomerLink entityId={value as InternalId} />;
                                } else if (name === 'contractId') {
                                  displayValue = <ContractLink entityId={value as InternalId} />;
                                } else if (name === 'contractRevisionId') {
                                  displayValue = <ContractRevisionLink entityId={value as InternalId} />;
                                } else if (name === 'projectId') {
                                  displayValue = <ProjectLink entityId={value as InternalId} />;
                                } else if (name === 'siteId') {
                                  displayValue = <SiteLink entityId={value as InternalId} />;
                                } else if (name === 'syncCoreId') {
                                  displayValue = <SyncCoreLink entityId={value as InternalId} />;
                                } else if (name === 'invoiceId') {
                                  displayValue = <InvoiceLink entityId={value as InternalId} />;
                                } else if (name === 'userId') {
                                  displayValue = <UserName entityId={value as InternalId} />;
                                } else {
                                  displayValue = value as string;
                                }
                              } else {
                                displayValue = value as string;
                              }
                              displayValue = (
                                <>
                                  {displayValue} <IconButton icon={faFilter} onClick={() => setFilter(name, value, true)} />
                                </>
                              );
                            } else {
                              displayName = name;
                              if (typeof value === 'string') {
                                displayValue = value;
                              } else if (typeof value === 'number') {
                                displayValue = value;
                              } else if (typeof value === 'boolean') {
                                displayValue = value ? 'true' : 'false';
                              } else if (value) {
                                displayValue = JSON.stringify(value);
                              } else {
                                displayValue = 'null';
                              }
                            }
                            return (
                              <div key={name}>
                                <Badge bg="light" className="me-1">
                                  {displayName}
                                </Badge>
                                {displayValue}
                              </div>
                            );
                          })}
                      </Col>
                    </Row>
                  );
                }

                return (
                  <Row key={index} className="mt-2">
                    <Col xs={2}>{item.timestamp ? moment(item.timestamp).format('YYYY/MM/DD HH:mm:ss.SSS') : ''}</Col>
                    <Col xs={2} />
                    <Col xs={4}>{item.message || <em>(empty)</em>}</Col>
                  </Row>
                );
              })
            ) : (
              <em>No results.</em>
            )
          ) : (
            this.renderRequest(RequestReason.RequestDetails)
          )}

          <div className="mt-5 mx-auto text-center" style={{ width: '200px' }}>
            <Button variant="light" disabled={disabled || !result || !token} onClick={() => this.update()}>
              First
            </Button>{' '}
            <Button
              variant="light"
              disabled={disabled || !result || !result.hasMore}
              onClick={() => this.update(result!.nextToken || undefined)}
            >
              Next
            </Button>
          </div>
        </ContentBox>
      </div>
    );
  }
}
